/* Newly added styles */


:root {
  --main-color: rgba(127, 17, 224, 0.3);
  --main-color-transparent: rgb(15, 77, 168, 0.4);
  --second-color: #00A383;
  --third-color: #ffa100;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: rgba(15, 77, 168, 0.1);
  --neutral-color: #fff;
  --bg-selected: rgba(15, 77, 168, 0.2);
}

a {
  text-decoration: none;
}

.nav-header:hover {
  color: var(--third-color);
}

.nav-header.active {
  color: var(--third-color);
}

.nav-header.active:hover {
  color: white;
}


/* Pre-existing styles */
/* generic stylesheet */
div.MuiChip-root, div[class*="MUIDataTableFilterList"] {
  width: auto;
}
/* [id*="mui-"] {
  display: none;
}
*/
div[class*="MUIDataTablePagination"],
div[class*="MUIDataTablePagination"] div,
[class*="MuiTablePagination"] div,
div[class*="MuiPopover"] div {
  width: auto;
}
.text-danger {
  color: #dc3545;
}
td {
  word-break: break-all;
}
.MuiMenu-paper {
  max-width: 400px;
  width: auto;
  margin: auto;
  /* position: relative; */
}
.MuiAutocomplete-endAdornment
{
  width: auto;
}
.d-none {
  display: none;
}

.MuiPickersPopper-root {
  position: absolute !important;
  inset: auto auto 0px 0px !important;
  margin: 0 !important;
  transform: translate(0px, -20px) !important;
  left: 50% !important;
  transform: translateX(-50%) translateY(-20px) !important;
  width: 570px !important;
}
.MuiTooltip-popper {
  display: none; /* Hides the tooltip completely */
}

.MuiList-padding{
  width: auto !important;
}
.MuiInputAdornment-root{
  width: auto !important;
}
.MuiMultiSectionDigitalClock-root{
  ul{
    max-height: 336px;
    overflow: hidden;
    overflow-y: auto;
  }

}
